.about {
  background-color: $color-white;

  &__content {
    display: flex;
    align-items: center;
    margin: 0 auto;
    max-width: 92rem;

    @include respond(tab-port) {
      flex-direction: column;
    }

    &--picture {
      border-radius: 50%;
      margin-right: 5rem;
      height: 20rem;

      @include respond(tab-port) {
        margin: 6rem auto;
        margin-bottom: 0;
      }

      @media screen and (max-width: 270px) {
        height: 10rem;
      }
    }

    &--text {
      font-size: 1.8rem;

      & p {
        margin: 2.5rem 0;
        text-align: justify;
      }
    }
  }
}
