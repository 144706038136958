.nav-container {
  box-shadow: 10px 1px 17px 1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 10px 1px 17px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 1px 17px 1px rgba(0, 0, 0, 0.75);
  position: sticky;
  top: 0;
  width: 100%;
  height: 7rem;
  background-color: $color-white;
  z-index: 30;

  &__nav {
    display: flex;
    align-items: center;
    height: 7rem;
  }

  &__logo {
    width: 5rem;
    margin-left: 2rem;
  }

  &__link {
    font-size: 1.6rem;
    margin: 0 5rem 0 0;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: 700;
    transition: transform 200ms;
    color: $color-black;
    padding-bottom: 3px;
    border-bottom: 2px solid transparent;

    &:hover {
      border-color: $color-primary;
      color: $color-primary;
      transform: scale(1.05);
      transition: transform 200ms;
      cursor: pointer;
    }

    &--logo {
      margin-right: auto;

      &:hover {
        border-bottom: none;
      }
    }
  }
}

.active {
  border-color: $color-primary;
  color: $color-primary;
  padding-bottom: 3px;
}
