// COLORS
$color-primary: #136290;
$color-primary-light: #177bb5;
$color-primary-dark: #25283d;

$color-white: #fff;
$color-black: #000;
$color-grey: #d5d6d8;

$color-error: #ff6663;
$color-success: #28a46a;
