.experience {
  background-color: $color-grey;

  &__content {
    margin: 0 auto;
    max-width: 95rem;

    &:not(:last-child) {
      margin-bottom: 4rem;
    }

    &--work {
      margin-top: 4rem;
    }

    &--subheading {
      color: $color-primary-dark;
      margin-bottom: 2rem;
      font-size: 2rem;
      text-transform: uppercase;
      display: inline-block;
    }
  }
}
