@import "../../assets/stylesheets/global/variables";
@import "../../assets/stylesheets/global/mixins";
@import "../../assets/stylesheets/global/animations.scss";
@import "../Nav/Nav";
@import "../HamburgerMenu/HamburgerMenu";
@import "../Header/Header";
@import "../About/About";
@import "../Experience/Experience";
@import "../EducationCard/EducationCard";
@import "../WorkCard/WorkCard";
@import "../Skills/Skills";
@import "../Projects/Projects";
@import "../ProjectCard/ProjectCard";
@import "../Contact/Contact";
@import "../Footer/Footer";
@import "../Particle/Particle";
@import "../Toast/Toast";

.section-heading {
  text-align: center;
  font-size: 3rem;
  font-family: "Ubuntu Mono";
}

section {
  padding: 9rem 8rem 8rem;

  @media (max-width: 1000px) {
    padding: 9rem 4rem;
  }

  @media (max-width: 600px) {
    padding: 9rem 2rem 8rem;
  }

  @media screen and (max-width: 270px) {
    padding: 9rem 1rem 8rem;
  }
}
