.skills {
  display: flex;
  flex-direction: column;

  &__content {
    display: flex;
    align-items: center;
    margin: 0 auto 4rem auto;
    background-color: $color-white;
    padding: 2rem;
    border-radius: 3px;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;

    &--item {
      background: $color-primary-dark;
      height: 4rem;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem;
      padding: 2rem;

      h4 {
        font-size: 1.5rem;
        color: $color-white;
      }
    }
  }
}
