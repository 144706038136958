.work {
  &__content {
    background-color: $color-white;
    padding: 4rem;
    border-radius: 3px;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }

    &--header {
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: 415px) {
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
      }

      &-info {
        a {
          text-decoration-color: transparent;
          text-underline-offset: 2px;
          color: $color-primary;
          transition: 200ms;

          &:hover {
            text-decoration-color: $color-primary;
          }
        }

        h3 {
          font-size: 2rem;
        }

        h4 {
          font-size: 1.5rem;
          margin: 2rem 0;
        }
      }
    }

    &--logo {
      border-radius: 50%;
      height: 12rem;
    }

    &--text {
      @media only screen and (max-width: 415px) {
        text-align: center;
      }

      &-list {
        li {
          font-size: 1.4rem;

          &:not(:last-child) {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
