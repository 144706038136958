header {
  width: 100%;
}

.welcome,
.social-icons-container,
.down-arrow-container {
  @include absFlexCenter;
}

#name-container {
  top: 20rem;

  @include respond(tab-port) {
    display: block;
    text-align: center;
    top: 13rem;
  }
}

#industry-container {
  top: 30rem;
}

h1 {
  font-size: 8rem;

  @include respond(tab-port) {
    font-size: 6rem;
  }
}

.first-name {
  margin-right: 3rem;
  @include respond(tab-port) {
    margin-right: 0rem;
  }

  @media screen and (max-width: 390px) {
    font-size: 5rem;
  }
}

.first-name,
.last-name {
  @media screen and (max-width: 270px) {
    font-size: 3rem;
  }
}

h1,
.sub-heading {
  letter-spacing: 1.1rem;
  text-transform: uppercase;
  text-shadow: 4px 1px 4px rgba(21, 4, 4, 1.29);
  color: $color-white;
}

.sub-heading {
  font-size: 3rem;
  @include respond(phone) {
    font-size: 2rem;
  }

  @media screen and (max-width: 390px) {
    text-align: center;
  }

  @media screen and (max-width: 270px) {
    font-size: 2rem;
  }
}

.scale-in-center {
  -webkit-animation: scale-in-center 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    0.3s both;
  animation: scale-in-center 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s both;
}

.scale-in-center-second {
  -webkit-animation: scale-in-center 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    1s both;
  animation: scale-in-center 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
}

.social-icons-container {
  top: 42rem;
}

.social-icons-container a {
  &:not(:last-child) {
    margin-right: 5rem;
  }
}

.social-icon {
  width: 5rem;
  transform: scale(1);
  transition: transform 0.5s;

  -webkit-animation: fade-in 4s ease-in-out 1s both;
  animation: fade-in 4s ease-in-out 1s both;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: transform 0.5s;
  }

  @media screen and (max-width: 200px) {
    width: 3rem;
  }
}

.down-arrow-container {
  top: 58rem;
}

.down-arrow {
  background: center/100% url(../../assets/images/down-arrow.svg);
  border: none;
  height: 5rem;
  width: 5rem;

  -webkit-animation: blinking 1s linear infinite alternate-reverse,
    fade-in 5s ease-in-out 1s both;
  animation: blinking 1s linear infinite alternate-reverse,
    fade-in 5s ease-in-out 1s both;

  &:hover {
    cursor: pointer;
    animation-play-state: paused;
  }
}
