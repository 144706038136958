.education {
  &__content {
    display: flex;
    align-items: center;
    background-color: $color-white;
    padding: 2rem;
    border-radius: 3px;
    flex-direction: row-reverse;

    @media only screen and (max-width: 415px) {
      flex-direction: column;
    }

    &:not(:last-child) {
      margin-bottom: 3rem;
    }

    &--text {
      margin: 0 auto 0 1rem;

      a {
        text-decoration-color: transparent;
        text-underline-offset: 2px;
        color: $color-primary;
        transition: 200ms;

        &:hover {
          text-decoration-color: $color-primary;
        }
      }

      h3 {
        font-size: 2rem;
      }
      h4 {
        font-size: 1.5rem;
        margin-top: 1rem;
      }

      @media only screen and (max-width: 415px) {
        text-align: center;
        margin: 0;
      }
    }

    &--logo {
      height: 12rem;
      border-radius: 50%;

      @include respond(phone) {
        height: 12rem;
        margin-right: 2rem;
      }

      @media only screen and (max-width: 415px) {
        margin: 0 auto;
      }
    }
  }
}
