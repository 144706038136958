.contact {
  background: $color-white;
  text-align: center;
  font-size: 1.8rem;
  position: relative;

  h2 + p {
    margin: 2rem 0;
  }

  &__cta {
    max-width: 49rem;
    margin: 0 auto 2rem;
    border-top: 1px solid $color-black;
    padding-top: 1.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      text-decoration: none;
      border-bottom: 1px solid transparent;
      color: $color-primary-light;
      transition: 200ms;

      @media screen and (max-width: 210px) {
        font-size: 1.4rem;
      }
    }
    a:hover {
      border-color: $color-primary-light;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;

    input {
      border: 1px solid $color-black;
      border-radius: 3px;
      padding-left: 8px;
      margin-bottom: 2rem;
      height: 4rem;
      font-size: 1.4rem;
    }

    textarea {
      border: 1px solid $color-black;
      border-radius: 3px;
      padding: 8px;
      margin-bottom: 2rem;
      font-size: 14px;
      font-family: inherit;
      height: 200px;
      resize: vertical;
    }

    input:focus,
    textarea:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, 0.1);
      border-bottom: 3px solid $color-success;
    }

    input:focus:invalid,
    textarea:focus:invalid {
      border-bottom: 3px solid $color-error;
    }

    input::placeholder,
    input::-webkit-input-placeholder,
    textarea::placeholder {
      color: $color-primary-light;
    }

    button {
      height: 4rem;
      border-radius: 3px;
      background-color: $color-primary;
      border: 2px solid $color-primary;
      color: $color-white;
      font-size: 1.8rem;
      transition: 200ms;

      &:hover {
        cursor: pointer;
        background-color: $color-primary-light;
        border: 2px solid $color-primary-light;
        transform: scale(1.025);
      }

      &:active {
        transform: scale(1.01);
        outline: none;
      }
    }

    label {
      align-self: flex-start;
      font-size: 1.4rem;
      margin-bottom: 4px;
      font-weight: 600;
    }
  }
}
