footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8rem;
  width: 100%;
  padding: 2rem;
  background-color: $color-primary-dark;
  color: $color-white;
  font-size: 1.3rem;
  font-weight: 600;

  @media only screen and (max-width: 375px) {
    flex-direction: column;
  }
}

footer h2 {
  font-size: 1.3rem;
  text-transform: uppercase;
}

footer a {
  color: $color-white;
  border-bottom: 1px solid transparent;
  text-decoration: none;
  text-transform: uppercase;
  transition: 200ms;
}

footer a:hover {
  border-color: $color-white;
}
