.projects {
  background-color: rgba(97, 137, 168, 0.9);

  &__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    margin-top: 3rem;

    @media only screen and (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
      grid-row-gap: 3rem;
    }
  }
}
